<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center question-order row-question-order">
                            <h4 class="card-title">{{ $t('Archived Categories') }}</h4>
                            <div class="header-buttons">
                                <button class="btn btn-primary" @click="showCategories">{{ $t('Go Back') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :isLoading.sync="isLoading" @on-search="searchFn" :search-options="{enabled: true,searchFn: searchFn}"
                            :pagination-options="{
                                enabled: true,
                                dropdownAllowAll: false,
                                perPageDropdown: [10, 20, 50, 100, 200],
                            }" :rows="rows" :columns="tableColumns" :line-numbers="true">
                            <template slot="table-row" slot-scope="props">
                                <span
                                    v-if="props.column.field === 'action'"
                                    class="text-nowrap"
                                >
                                <b-button size="sm" v-if="props.row.status == 2"
                                    class="mr-1 btn-disabled text-white btn-restore" @click="restoreCategory(props.row.id)">
                                    {{ $t('restore-btn') }}
                                </b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
    import Vue from "vue";
    import SortedTablePlugin from "vue-sorted-table";
    import API from '@/api'
    import Snotify, {
        SnotifyPosition
    } from 'vue-snotify'
    import {
        validationMixin
    } from "vuelidate";
    import {
        required,
        minLength,
        email,
        requiredIf,
        sameAs,
        between
    } from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    Vue.use(SortedTablePlugin, {
        ascIcon: '<i class="ti-arrow-down"></i>',
        descIcon: '<i class="ti-arrow-up"></i>'
    });
    export default {
        name: 'ArchivedSpotType',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    name: "",
                    id: "",
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    show: true,
                },
                isDisabled: false,
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    columnFilters: {},
                    sort: [{
                        field: 'id',
                        type: 'desc'
                    }],
                    page: 1,
                    perPage: 10
                },
                columns: [{
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Geo Marker Color',
                        field: 'category_color',
                        sortable: true,
                    },
                    {
                        label: 'Created At',
                        field: 'created_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Deleted At',
                        field: 'updated_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                totalRecords: 0,
                categories: [],
                visible: true,
                version_id: null,
            };
        },
        validations: {
            form: {
                id: {},
                name: {
                    required,
                },
            },
        },
        methods: {
            restoreCategory(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You want to restore category!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Restore it!'
                }).then((result) => {
                    if (result.value) {
                        let data = {
                            id: e
                        }
                        API.restoreCategory(
                            e,
                            data => {
                                this.loadItems();
                                this.$snotify.success('Record restored successfully.');
                                // this.$Snotify.success(data.message)
                            },
                            err => {}
                        )
                    }
                })
            },
            showCategories() {
                this.$router.push({
                    name: "categories",
                });
                
            },
            loadItems() {
                this.isLoading = true;
                API.getArchivedCategories(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.version_id = data.version_id;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        this.rows.forEach(q => {
                            const data = {
                                id: q.id,
                            };
                            this.categories.push(data);
                        });
                    },
                    err => {}
                )
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({
                    searchTerm: params
                });
                this.loadItems();
                this.isLoading = false;
            },
            formatStatus(status) {
                if (status === 1) {
                    return true;
                } else {
                    return false;
                }
            },
            onPerPageChange(params) {
                this.updateParams({
                    perPage: params.currentPerPage
                });
                this.loadItems();
            },
    
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
    
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
        },
        computed: {
            tableColumns() {
                let columns;
                if (this.version_id === 2 || this.version_id === 3) {
                    columns = [
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Geo Marker Color',
                        field: 'category_color',
                        sortable: true,
                    },
                    {
                        label: 'Created At',
                        field: 'created_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Updated At',
                        field: 'updated_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                    ];

                }
                else{
                    columns = [
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Created At',
                        field: 'created_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Updated At',
                        field: 'updated_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                    ];
                }

            return columns;
        }
        },
        created() {
            // this.getFixLocations();
        },
        mounted() {
            this.isLoading = true;
            this.loadItems();
        }
    }
</script>


<style>
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
    .submit-btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-right: auto;
        margin: 0 auto;
    }

    .new-spotType {
        float: right;
    }
    .save-btn {
        float: left;
    }
    .btn-disabled {
        width: 100px;
    }
    .btn-restore {
        width: 70px !important;
        padding-left: 10px !important;
        background: #5D9E12;
        border-color: #5D9E12;
    }
</style>
